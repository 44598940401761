import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { getAwsAuthException } from "../functions";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "30px"
  }
}));

const CompletarNuevaContrasena = ({
  authState = "",
  authData,
  onStateChange = () => {},
  onLoading = () => {},
  onLoaded = () => {}
}) => {
  const classes = useStyles();

  const [step, setStep] = useState(1);

  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const reset = () => {
    setOldPassword("");
    setOldPasswordErrorMessage("");

    setPassword("");
    setPasswordErrorMessage("");

    setConfirmPassword("");
    setConfirmPasswordErrorMessage("");

    setErrorMessage("");
  };

  const validate = () => {
    let result = true;

    if (!oldPassword || oldPassword === "") {
      setOldPasswordErrorMessage("La contraseña antigua no puede estar vacía");
      result = false;
    } else if (oldPassword.length < 8) {
      setOldPasswordErrorMessage("La contraseña antigua debe tener al menos 8 caracteres");
    } else {
      setOldPasswordErrorMessage("");
    }

    if (!password || password === "") {
      setPasswordErrorMessage("La nueva contraseña no puede estar vacía");
      result = false;
    } else if (password.length < 8) {
      setPasswordErrorMessage("La nueva contraseña debe tener al menos 8 caracteres");
    } else {
      setPasswordErrorMessage("");
    }

    if (!confirmPassword || confirmPassword === "") {
      setConfirmPasswordErrorMessage("La confirmación no puede estar vacía");
      result = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordErrorMessage("La nueva contraseña y su confirmación no coinciden");
      result = false;
    } else {
      setConfirmPasswordErrorMessage("");
    }

    return result;
  };

  const completeNewPassword = async e => {
    e.preventDefault();
    setErrorMessage("");

    if (validate()) {
      onLoading();
      setLoading(true);

      const username = authData.challengeParam.userAttributes.email;

      try {
        let user = await Auth.signIn(username, oldPassword);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          await Auth.completeNewPassword(user, password);
        }

        reset();
        onLoaded();
        setLoading(false);

        setStep(2);
      } catch (err) {
        onLoaded();
        setLoading(false);

        if (err.code === "NotAuthorizedException") {
          onStateChange("signIn", {});
        }

        const message = getAwsAuthException(err.code, err.message);
        setErrorMessage(message);
      }
    }
  };

  if (authState !== "signedIn" || !authData || authData.challengeName !== "NEW_PASSWORD_REQUIRED") {
    return null;
  }

  const renderForm = () => {
    return (
      <form className={classes.form} autoComplete="off" noValidate onSubmit={completeNewPassword}>
        <Grid container spacing={2}>
          {errorMessage && (
            <Grid item xs={12} className="mb-3">
              <Typography color="error" variant="body2">
                {errorMessage}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="oldPassword"
              name="password"
              label="Contraseña actual"
              type="password"
              autoComplete="old-password"
              onChange={({ target }) => setOldPassword(target.value)}
              disabled={loading}
              value={oldPassword}
              error={oldPasswordErrorMessage ? true : false}
              helperText={oldPasswordErrorMessage}
            />
          </Grid>

          <Grid item xs={12}>
            <div className="mt-5">
              <Typography color="textSecondary" variant="body2">
                Recuerda usar 8 o más caracteres y combinar letras y números.
              </Typography>
            </div>
            <div>
              <TextField
                required
                fullWidth
                id="password"
                name="password"
                label="Nueva contraseña"
                type="password"
                autoComplete="new-password"
                onChange={({ target }) => setPassword(target.value)}
                disabled={loading}
                value={password}
                error={passwordErrorMessage ? true : false}
                helperText={passwordErrorMessage}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label="Confirmar nueva contraseña"
              type="password"
              autoComplete="confirm-new-password"
              onChange={({ target }) => setConfirmPassword(target.value)}
              disabled={loading}
              value={confirmPassword}
              error={confirmPasswordErrorMessage ? true : false}
              helperText={confirmPasswordErrorMessage}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.submit}
          color="secondary"
          disabled={loading}
        >
          {!loading ? "Cambiar contraseña" : <CircularProgress size={30} />}
        </Button>

        <Grid container justify="center">
          <Grid item>
            <Typography color="textSecondary" variant="body2">
              <Button color="primary" onClick={() => onStateChange("signIn", {})}>
                Volver a Iniciar sesión
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </form>
    );
  };

  const renderConfirmation = () => {
    return (
      <>
        <Typography variant="h6" align="center" className="mt-5">
          Tu contraseña ha sido cambiada{" "}
          <span role="img" aria-label=":grinning_face:">
            😀
          </span>
        </Typography>

        <Button
          fullWidth
          variant="contained"
          className={classes.submit}
          color="secondary"
          onClick={() => onStateChange("signIn", {})}
        >
          Iniciar sesión
        </Button>
      </>
    );
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return renderForm();
      case 2:
        return renderConfirmation();
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="xs" className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" align="center" className="mt-3">
          Cambia tu contraseña
        </Typography>

        {renderStep()}
      </div>
    </Container>
  );
};

export default CompletarNuevaContrasena;
