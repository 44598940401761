import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Skeleton from "@material-ui/lab/Skeleton";
import logger from "../logger";
import DetalleReembolso from "../components/DetalleReembolso";
import MisReembolsos from "../components/MisReembolsos";
import { AppContext } from "./App";

const Home = () => {
  const { afiliado, cuenta } = useContext(AppContext);
  const matchesMobile = useMediaQuery("(max-width:768px)");
  let history = useHistory();
  const isAfiliado = afiliado !== null;
  const [loading, setLoading] = useState(true);
  const [reembolsosData, setReembolsosData] = useState([]);

  async function getReembolsos(afiliadoId, cuentaId) {
    try {
      const init = {
        queryStringParameters: {
          afiliadoId,
          cuentaId
        }
      };
      const result = await API.get(process.env.REACT_APP_AFILIADOS_API, "/api/solicitudes", init);

      if (result.success && result.solicitudes) {
        setReembolsosData(result.solicitudes);
      }
    } catch (err) {
      logger("Home.getReembolsos.error", err.message, "error");
    }

    setLoading(false);
  }

  useEffect(() => {
    if (afiliado && cuenta) {
      getReembolsos(afiliado.id_contacto, cuenta.id_cuenta);
    }
  }, [afiliado, cuenta]);

  const renderAfiliadoHome = () => {
    let component = undefined;

    if (!loading && reembolsosData.length > 0) {
      component = matchesMobile ? (
        <>
          <MisReembolsos reembolsos={reembolsosData} />

          <div className="fab-icons">
            <Fab
              color="secondary"
              aria-label="add"
              onClick={() => history.push("/nuevo-reembolso")}
            >
              <AddIcon />
            </Fab>
          </div>
        </>
      ) : (
        <div className="multipage">
          <MisReembolsos reembolsos={reembolsosData} />
          <DetalleReembolso />

          <div className="fab-icons">
            <Fab
              color="secondary"
              aria-label="add"
              onClick={() => history.push("/nuevo-reembolso")}
            >
              <AddIcon />
            </Fab>
          </div>
        </div>
      );
    } else if (!loading && reembolsosData.length < 1) {
      component = (
        <div className={`page home ${isAfiliado ? "afiliado" : "no-afiliado"}`}>
          <h1>¡Hola!</h1>
          <h2>Ahora puedes solicitar reembolsos cuando estimes conveniente</h2>
          <Link to="/nuevo-reembolso" className="button">
            Quiero solicitar un reembolso
          </Link>
        </div>
      );
    } else {
      component = (
        <div className={`page home ${isAfiliado ? "afiliado" : "no-afiliado"}`}>
          <h1>¡Hola!</h1>
          <h2>Estamos consultando tus reembolsos</h2>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </div>
      );
    }

    return component;
  };

  return isAfiliado ? (
    renderAfiliadoHome()
  ) : (
    <div className={`page home ${isAfiliado ? "afiliado" : "no-afiliado"}`}>
      <h1>¡Falta poco!</h1>
      <h2>Para solicitar tus reembolsos debes completar tu perfil</h2>

      <Button
        component={Link}
        to="/perfil/completar"
        className="button link"
        variant="contained"
        color="primary"
      >
        completar perfil
      </Button>
    </div>
  );
};

export default Home;
