import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { Auth } from "aws-amplify";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { validateEmail, getAwsAuthException } from "../functions";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "30px"
  }
}));

const RestablecerContrasena = ({
  authState = "",
  onStateChange = () => {},
  onLoading = () => {},
  onLoaded = () => {}
}) => {
  const classes = useStyles();

  const [step, setStep] = useState(1);

  const [username, setUsername] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");

  const [code, setCode] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const reset = () => {
    setStep(1);
    setUsername("");
    setUsernameErrorMessage("");
    setCode("");
    setCodeErrorMessage("");
    setPassword("");
    setPasswordErrorMessage("");
    setConfirmPassword("");
    setConfirmPasswordErrorMessage("");
    setErrorMessage("");
  };

  const validate = () => {
    let result = true;

    if (step === 1) {
      if (!username || username === "") {
        setUsernameErrorMessage("El email no puede estar vacío");
        result = false;
      } else if (!validateEmail(username)) {
        setUsernameErrorMessage("Debe ingresar un email válido");
      } else {
        setUsernameErrorMessage("");
      }
    }

    if (step === 2) {
      if (!code || code === "") {
        setCodeErrorMessage("El código de verificación no puede estar vacío");
        result = false;
      } else {
        setCodeErrorMessage("");
      }

      if (!password || password === "") {
        setPasswordErrorMessage("La contraseña no puede estar vacía");
        result = false;
      } else if (password.length < 8) {
        setPasswordErrorMessage("La contraseña debe tener al menos 8 caracteres");
      } else {
        setPasswordErrorMessage("");
      }

      if (password !== confirmPassword) {
        setConfirmPasswordErrorMessage("La contraseña y su confirmación no coinciden");
        result = false;
      } else {
        setConfirmPasswordErrorMessage("");
      }
    }

    return result;
  };

  const forgotPassword = async e => {
    e.preventDefault();
    setErrorMessage("");

    if (validate()) {
      onLoading();
      setLoading(true);

      try {
        if (step === 1) {
          await Auth.forgotPassword(username);
          setStep(2);
          onLoaded();
          setLoading(false);
        } else if (step === 2) {
          await Auth.forgotPasswordSubmit(username, code, password);
          reset();
          onLoaded();
          setLoading(false);
          onStateChange("signIn", {});
        }
      } catch (err) {
        const message = getAwsAuthException(err.code, err.message);
        onLoaded();
        setLoading(false);
        setErrorMessage(message);
      }
    }
  };

  if (authState !== "forgotPassword") {
    return null;
  }

  const MainButton = () => {
    let buttonText = "";

    if (step === 1) buttonText = "Solicitar constraseña";
    else if (step === 2) buttonText = "Restablecer constraseña";

    return (
      <Button
        type="submit"
        fullWidth
        variant="contained"
        className={classes.submit}
        color="secondary"
        disabled={loading}
      >
        {!loading ? buttonText : <CircularProgress size={30} />}
      </Button>
    );
  };

  return (
    <Container maxWidth="xs" className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className="my-3">
          Restablecer contraseña
        </Typography>
        <form className={classes.form} autoComplete="off" noValidate onSubmit={forgotPassword}>
          <Grid container>
            {errorMessage && (
              <Grid item xs={12} className="mb-3">
                <Typography color="error" variant="body2">
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                value={username}
                onChange={({ target }) => setUsername(target.value.toLowerCase())}
                disabled={step === 2 || loading}
                error={usernameErrorMessage ? true : false}
                helperText={usernameErrorMessage}
              />
            </Grid>

            {step === 2 && (
              <>
                <Grid item xs={12}>
                  <NumberFormat
                    customInput={TextField}
                    format="######"
                    variant="standard"
                    autoComplete="off"
                    required
                    fullWidth
                    name="code"
                    label="Código de verificación"
                    id="code"
                    onChange={({ target }) => setCode(target.value)}
                    disabled={loading}
                    value={code}
                    error={codeErrorMessage ? true : false}
                    helperText={codeErrorMessage}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    name="password"
                    label="Nueva contraseña"
                    type="password"
                    id="password"
                    onChange={({ target }) => setPassword(target.value)}
                    disabled={loading}
                    value={password}
                    error={passwordErrorMessage ? true : false}
                    helperText={passwordErrorMessage}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirmar nueva contraseña"
                    type="password"
                    id="confirmPassword"
                    onChange={({ target }) => setConfirmPassword(target.value)}
                    disabled={loading}
                    value={confirmPassword}
                    error={confirmPasswordErrorMessage ? true : false}
                    helperText={confirmPasswordErrorMessage}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <MainButton />

          <Grid container justify="center">
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                ¿Ya te has registrado?
                <Button
                  color="primary"
                  className="ml-1"
                  onClick={() => onStateChange("signIn", {})}
                >
                  Inicia sesión
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default RestablecerContrasena;
