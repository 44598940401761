import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { validateEmail, getAwsAuthException } from "../functions";
import logger from "../logger";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "30px"
  }
}));

const Registro = ({
  authState = "",
  onStateChange = () => {},
  onLoading = () => {},
  onLoaded = () => {}
}) => {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");

  const [usernameConfirm, setUsernameConfirm] = useState("");
  const [usernameConfirmErrorMessage, setUsernameConfirmErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const reset = () => {
    setUsername("");
    setUsernameErrorMessage("");

    setUsernameConfirm("");
    setUsernameConfirmErrorMessage("");

    setPassword("");
    setPasswordErrorMessage("");

    setErrorMessage("");
  };

  const validate = () => {
    let result = true;

    if (!username || username === "") {
      setUsernameErrorMessage("El email no puede estar vacío");
      result = false;
    } else if (!validateEmail(username)) {
      setUsernameErrorMessage("Debe ingresar un email válido");
      result = false;
    } else {
      setUsernameErrorMessage("");
    }

    if (!usernameConfirm || usernameConfirm === "") {
      setUsernameConfirmErrorMessage("El email no puede estar vacío");
      result = false;
    } else if (!validateEmail(usernameConfirm)) {
      setUsernameConfirmErrorMessage("Debe ingresar un email válido");
      result = false;
    } else if (usernameConfirm !== username) {
      setUsernameConfirmErrorMessage("El email no coincide");
      result = false;
    } else {
      setUsernameConfirmErrorMessage("");
    }

    if (!password || password === "") {
      setPasswordErrorMessage("La contraseña no puede estar vacía");
      result = false;
    } else if (password.length < 8) {
      setPasswordErrorMessage("La contraseña debe tener al menos 8 caracteres");
      result = false;
    } else {
      setPasswordErrorMessage("");
    }

    return result;
  };

  const signUp = async e => {
    e.preventDefault();
    setErrorMessage("");

    if (validate()) {
      onLoading();
      setLoading(true);

      try {
        const result = await Auth.signUp({
          username,
          password
        });

        reset();
        onLoaded();
        setLoading(false);
        signedUp();

        onStateChange("confirmSignUp", result);
      } catch (err) {
        const message = getAwsAuthException(err.code, err.message);
        onLoaded();
        setLoading(false);
        setErrorMessage(message);
      }
    }
  };

  const gotoSignIn = () => {
    onStateChange("signIn", {});
  };

  const signedUp = () => {
    logger("Registro.signedUp");
  };

  if (authState !== "signUp") {
    return null;
  }

  return (
    <Container maxWidth="xs" className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className="mt-3">
          Crea tu cuenta
        </Typography>
        <form className={classes.form} autoComplete="off" noValidate onSubmit={signUp}>
          <Grid container spacing={2}>
            {errorMessage && (
              <Grid item xs={12} className="mb-3">
                <Typography color="error" variant="body2">
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Correo electrónico"
                autoComplete="new-email"
                value={username}
                onChange={({ target }) => setUsername(target.value.toLowerCase())}
                disabled={loading}
                error={usernameErrorMessage ? true : false}
                helperText={usernameErrorMessage}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Confirmar correo electrónico"
                value={usernameConfirm}
                onChange={({ target }) => setUsernameConfirm(target.value.toLowerCase())}
                disabled={loading}
                error={usernameConfirmErrorMessage ? true : false}
                helperText={usernameConfirmErrorMessage}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Contraseña"
                type="password"
                autoComplete="new-password"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                disabled={loading}
                error={passwordErrorMessage ? true : false}
                helperText={passwordErrorMessage}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" variant="body2">
                Usa 8 o más caracteres y combina letras y números.
              </Typography>
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            disabled={loading}
          >
            {!loading ? "Crear cuenta" : <CircularProgress size={30} />}
          </Button>

          <Grid container justify="center">
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                ¿Ya te has registrado?
                <Button color="primary" className="ml-1" onClick={() => gotoSignIn()}>
                  Inicia sesión
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default Registro;
