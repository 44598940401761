import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const tipos = [
  { label: "Cuenta de Ahorros", value: "CUENTA_AHORRO" },
  { label: "Cuenta Vista", value: "CUENTA_VISTA" },
  { label: "Cuenta Corriente", value: "CUENTA_CORRIENTE" },
  { label: "Chequera Electrónica", value: "CHEQUERA_ELECTRONICA" }
];

const SelectTipoCuenta = ({
  id = "--select-tipo-cuenta",
  className,
  disabled,
  variant,
  required,
  placeholder,
  size,
  error,
  helperText,
  value,
  onChange = () => {},
  onBlur = () => {}
}) => {
  return (
    <Autocomplete
      id={id}
      className={className}
      disabled={disabled}
      options={tipos}
      getOptionLabel={option => (option.label ? option.label : option)}
      value={value}
      onChange={(_, value) => onChange(value)}
      onBlur={onBlur}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          required={required}
          label="Tipo de cuenta"
          placeholder={placeholder}
          size={size}
          variant={variant}
          InputLabelProps={{
            shrink: Boolean(value) || Boolean(placeholder) || document.activeElement.id === id
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export const getTipoCuenta = value => tipos.filter(t => t.value === value)[0] || null;

export default SelectTipoCuenta;
