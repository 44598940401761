import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { validateEmail, getAwsAuthException } from "../functions";
import logger from "../logger";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(5, 0, 2),
    borderRadius: "30px"
  }
}));

const ConfirmarRegistro = ({
  authState = "",
  authData = {},
  onStateChange = () => {},
  onLoading = () => {},
  onLoaded = () => {}
}) => {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [usernameDisabled, setUsernameDisabled] = useState(true);

  const [code, setCode] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [codeResend, setCodeResend] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (authData && authData.user && authData.user.username) {
      setUsername(authData.user.username);
      setUsernameDisabled(true);
    }
  }, [authData]);

  const reset = () => {
    setUsername("");
    setUsernameErrorMessage("");
    setCode("");
    setCodeErrorMessage("");
    setErrorMessage("");
  };

  const validateUsername = () => {
    let result = true;

    if (!username || username === "") {
      setUsernameErrorMessage("El email no puede estar vacío");
      result = false;
    } else if (!validateEmail(username)) {
      setUsernameErrorMessage("Debe ingresar un email válido");
    } else {
      setUsernameErrorMessage("");
    }

    return result;
  };

  const validate = () => {
    let result = true;

    result = validateUsername();

    if (!code || code === "") {
      setCodeErrorMessage("El código de verificación no puede estar vacío");
      result = false;
    } else {
      setCodeErrorMessage("");
    }

    if (!password || password === "") {
      setPasswordErrorMessage("La contraseña no puede estar vacía");
      result = false;
    } else {
      setPasswordErrorMessage("");
    }

    return result;
  };

  const confirmSignUp = async e => {
    e.preventDefault();
    setErrorMessage("");

    if (validate()) {
      onLoading();
      setLoading(true);

      try {
        await Auth.confirmSignUp(username, code);

        confirmed({ email: username });

        const user = await Auth.signIn({
          username,
          password
        });

        reset();
        onLoaded();
        setLoading(false);
        onStateChange("signedIn", user);
      } catch (err) {
        onLoaded();
        setLoading(false);

        if (err.code === "NotAuthorizedException") {
          onStateChange("signIn", {});
        }

        const message = getAwsAuthException(err.code, err.message);
        setErrorMessage(message);
      }
    }
  };

  const resendCode = async () => {
    setErrorMessage("");

    if (validateUsername()) {
      onLoading();
      setLoading(true);

      try {
        await Auth.resendSignUp(username);

        setCodeResend(true);

        setTimeout(() => {
          setCodeResend(false);
        }, 4000);

        onLoaded();
        setLoading(false);
      } catch (err) {
        const message = getAwsAuthException(err.code, err.message);
        onLoaded();
        setLoading(false);
        setErrorMessage(message);
      }
    }
  };

  const confirmed = ({ email }) => {
    logger("ConfirmarRegistro.confirmed");
  };

  if (authState !== "confirmSignUp") {
    return null;
  }

  return (
    <Container maxWidth="xs" className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className="mt-3">
          Confirmar registro
        </Typography>
        <Typography variant="body1" color="textSecondary" className="mt-2 mb-3 text-center">
          Te hemos enviado un código de verificación a tu correo electrónico
        </Typography>
        <form className={classes.form} autoComplete="off" noValidate onSubmit={confirmSignUp}>
          <Grid container spacing={2}>
            {errorMessage && (
              <Grid item xs={12} className="mb-3">
                <Typography color="error" variant="body2">
                  {errorMessage}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Email"
                autoComplete="new-email"
                value={username}
                onChange={({ target }) => setUsername(target.value.toLowerCase())}
                disabled={loading || usernameDisabled}
                error={usernameErrorMessage ? true : false}
                helperText={usernameErrorMessage}
              />
            </Grid>

            <Grid item xs={12}>
              <NumberFormat
                customInput={TextField}
                format="######"
                variant="standard"
                autoComplete="verification-code"
                required
                fullWidth
                label="Código de verificación"
                onChange={({ target }) => setCode(target.value)}
                disabled={loading}
                value={code}
                error={codeErrorMessage ? true : false}
                helperText={codeErrorMessage}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Contraseña"
                type="password"
                autoComplete="new-password"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                disabled={loading}
                error={passwordErrorMessage ? true : false}
                helperText={passwordErrorMessage}
              />
            </Grid>

            <Grid item xs={12} className="d-flex align-items-baseline">
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Button color="primary" className="mt-2 mb-3" onClick={resendCode}>
                    Reenviar código
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Fade in={codeResend}>
                    <Typography variant="body2" className="text-success ml-2">
                      Código enviado
                    </Typography>
                  </Fade>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            disabled={loading}
          >
            {!loading ? "Confirmar" : <CircularProgress size={30} />}
          </Button>

          <Grid container justify="center">
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                <Button color="primary" onClick={() => onStateChange("signIn", {})}>
                  Volver a Iniciar sesión
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default ConfirmarRegistro;
