import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { validateEmail, getAwsAuthException } from "../functions";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: "30px"
  }
}));

const Login = ({
  authState = "",
  onStateChange = () => {},
  onLoading = () => {},
  onLoaded = () => {}
}) => {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const reset = () => {
    setUsername("");
    setUsernameErrorMessage("");
    setPassword("");
    setPasswordErrorMessage("");
    setErrorMessage("");
  };

  const validate = () => {
    let result = true;

    if (!username || username === "") {
      setUsernameErrorMessage("El email no puede estar vacío");
      result = false;
    } else if (!validateEmail(username)) {
      setUsernameErrorMessage("Debe ingresar un email válido");
    } else {
      setUsernameErrorMessage("");
    }

    if (!password || password === "") {
      setPasswordErrorMessage("La contraseña no puede estar vacía");
      result = false;
    } else {
      setPasswordErrorMessage("");
    }

    return result;
  };

  const signIn = async e => {
    e.preventDefault();
    setErrorMessage("");

    if (validate()) {
      onLoading();
      setLoading(true);

      try {
        const user = await Auth.signIn({
          username,
          password
        });

        reset();
        onLoaded();
        setLoading(false);
        onStateChange("signedIn", user);
      } catch (err) {
        onLoaded();
        setLoading(false);

        if (err.code === "UserNotConfirmedException") {
          onStateChange("confirmSignUp", { user: { username } });
        } else {
          const message = getAwsAuthException(err.code, err.message);
          setErrorMessage(message);
        }
      }
    }
  };

  const gotoSignUp = () => {
    onStateChange("signUp", {});
  };

  const gotoForgotPassword = () => {
    onStateChange("forgotPassword", {});
  };

  if (authState !== "signIn") {
    return null;
  }

  return (
    <Container maxWidth="xs" className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className="mt-3">
          Iniciar sesión
        </Typography>
        <form className={classes.form} noValidate onSubmit={signIn}>
          <Grid container>
            {errorMessage && (
              <Grid item xs={12}>
                <Typography color="error" variant="body2">
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={username}
                onChange={({ target }) => setUsername(target.value.toLowerCase())}
                disabled={loading}
                error={usernameErrorMessage ? true : false}
                helperText={usernameErrorMessage}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                disabled={loading}
                error={passwordErrorMessage ? true : false}
                helperText={passwordErrorMessage}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            disabled={loading}
          >
            {!loading ? "Entrar" : <CircularProgress size={30} />}
          </Button>

          <Grid container className="mt-3" justify="space-between" spacing={2}>
            <Grid item xs={12}>
              <Button color="primary" onClick={gotoForgotPassword}>
                ¿Olvidaste tu contraseña?
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button color="primary" onClick={gotoSignUp}>
                ¿No tienes cuenta? Regístrate
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default Login;
