import React, { createContext, useState, useEffect, useReducer } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { API } from "aws-amplify";
import logger from "../logger";
import DetalleReembolso from "../components/DetalleReembolso";
import Loading from "../components/Loading";
import SideBar from "../components/SideBar";
import TopBar from "../components/TopBar";
import Home from "./Home";
import NuevoReembolso from "./NuevoReembolso";
import Perfil from "./Perfil";

export const AppContext = createContext("AppContext");

const initialState = {
  showSideBar: false
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "SHOW_SIDE_BAR":
      return { ...state, showSideBar: true };
    case "HIDE_SIDE_BAR":
      return { ...state, showSideBar: false };
    default:
      return { ...initialState };
  }
};

const App = ({ authState, authData }) => {
  const [afiliado, setAfiliado] = useState(null);
  const [cuentas, setCuentas] = useState([]);
  const [cuenta, setCuenta] = useState(null);
  const [reembolso, setReembolso] = useState(null);
  const [appState, dispatch] = useReducer(reducer, initialState);
  const [fetchingAfiliado, setFetchingAfiliado] = useState(true);
  const [fetchingCuentas, setFetchingCuentas] = useState(true);

  async function fetchAfiliado(userId) {
    setFetchingAfiliado(true);

    try {
      const init = {
        queryStringParameters: {
          userId
        }
      };

      const result = await API.get(process.env.REACT_APP_AFILIADOS_API, "/api/afiliado", init);

      if (result.success && result.afiliado) {
        setAfiliado(result.afiliado);
      }
    } catch (err) {
      logger("App.fetchAfiliado.error", err, "error");
    } finally {
      setFetchingAfiliado(false);
    }
  }

  async function fetchCuentas() {
    setFetchingCuentas(true);

    try {
      const result = await API.get(process.env.REACT_APP_AFILIADOS_API, "/api/cuentas");

      if (result.success && result.cuentas) {
        setCuentas(result.cuentas);

        if (result.cuentas.length === 1) {
          setCuenta(result.cuentas[0]);
        }
      }
    } catch (err) {
      logger("App.fetchCuentas.error", err, "error");
    } finally {
      setFetchingCuentas(false);
    }
  }

  useEffect(() => {
    if (authState === "signedIn" && authData) {
      fetchAfiliado(authData.username);
      fetchCuentas();
    }
  }, [authState, authData]);

  if (authState !== "signedIn" || (authData || {}).challengeName) {
    return null;
  }

  const context = {
    appState,
    authState,
    authData,
    cuentas,
    cuenta,
    afiliado,
    reembolso,
    dispatch,
    onSelectCuenta: c => setCuenta(c),
    onUpdateAfiliado: a => setAfiliado(a),
    onUpdateReembolso: r => setReembolso(r)
  };

  return (
    <AppContext.Provider value={context}>
      {fetchingAfiliado || fetchingCuentas ? (
        <Loading show />
      ) : (
        <BrowserRouter>
          <TopBar />
          <SideBar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              path="/perfil/:modo?"
              component={({ match }) => <Perfil modo={match.params.modo} />}
            />
            <Route path="/nuevo-reembolso" component={NuevoReembolso} />
            <Route path="/detalle-reembolso" component={DetalleReembolso} />
          </Switch>
        </BrowserRouter>
      )}
    </AppContext.Provider>
  );
};

export default App;
