import React from "react";
import Ver from "./perfil/Ver";
import Wizard from "./perfil/Wizard";

const Perfil = ({ modo }) => {
  const isWizard = modo === "completar";

  return (
    <div className={`page perfil ${isWizard ? "editor" : ""}`}>
      {isWizard ? <Wizard /> : <Ver />}
    </div>
  );
};

export default Perfil;
