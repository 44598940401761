import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Storage } from "aws-amplify";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { formatNumber } from "../functions";
import logger from "../logger";
import { AppContext } from "../pages/App";

const DetalleReembolso = () => {
  const { reembolso, cuentas } = useContext(AppContext);
  const matchesMobile = useMediaQuery("(max-width:768px)");
  let history = useHistory();

  const openFile = async document => {
    try {
      const result = await Storage.get(document.key);
      window.open(result, "_blank");
    } catch (e) {
      logger("DetalleReembolso.openFile.error", e.message, "error");
    }
  };

  const getEstado = (estado, cuentaId) => {
    let value = "Desconocido";

    for (let i = 0; i < cuentas.length; i++) {
      if (cuentas[i].id_cuenta === cuentaId) {
        if (estado < cuentas[i].pasos_pipeline.length) {
          value = cuentas[i].pasos_pipeline[estado];
        }
        break;
      }
    }

    return value;
  };

  return (
    <div className="page detalle-reembolso">
      {!matchesMobile && <h3>Detalle Reembolso</h3>}
      {reembolso ? (
        <div className="card">
          <div className="card-header">
            {matchesMobile && (
              <IconButton aria-label="settings" onClick={() => history.push("/")}>
                <ArrowBackIosIcon />
              </IconButton>
            )}
            <label>{reembolso.body_ticket.descripcion}</label>
          </div>
          <div className="card-body">
            <Typography color="textSecondary">
              Monto: {formatNumber(reembolso.body_ticket.monto, "currency")}
            </Typography>
            <Typography color="textSecondary">
              Fecha: {new Date(reembolso.fecha_creacion_ticket).toLocaleDateString()}
            </Typography>
            <Typography color="textSecondary">
              Estado: {getEstado(reembolso.estado_ticket, reembolso.id_cuenta)}
            </Typography>
          </div>
          <Divider variant="fullWidth" />
          <div className="card-body document-item">
            {reembolso.body_ticket.documentos &&
              reembolso.body_ticket.documentos.map(documento => (
                <Button
                  key={documento.id}
                  className="file"
                  variant="contained"
                  title={documento.name}
                  onClick={() => openFile(documento)}
                >
                  <>
                    {documento.extension === "pdf" ? (
                      <PictureAsPdfIcon fontSize="small" />
                    ) : (
                      <PhotoLibraryIcon fontSize="small" />
                    )}
                    <Typography className="ml-2" variant="body2">
                      {documento.name}
                    </Typography>
                  </>
                </Button>
              ))}
          </div>
        </div>
      ) : (
        <p>Por favor selecciona un reembolso</p>
      )}
    </div>
  );
};

export default DetalleReembolso;
