/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:854cd69d-0a2e-4832-ae04-30df3326dc61",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IMARK0kZy",
    "aws_user_pools_web_client_id": "7l4rt093sgemtkuoq8u4e0vlk3",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "serviclickafiliados",
            "endpoint": "https://8eqqbhch6e.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "serviclickafiliadosprod01234055-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
