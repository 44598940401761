import React, { useReducer, useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import NumberFormat from "react-number-format";
import { API, Storage } from "aws-amplify";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { PAYMENT_OPTIONS } from "../constants";
import logger from "../logger";
import ComprobanteReembolso from "../components/ComprobanteReembolso";
import DocumentUploader from "../components/DocumentUploader";
import SelectBanco, { getBanco } from "../components/SelectBanco";
import SelectTipoCuenta, { getTipoCuenta } from "../components/SelectTipoCuenta";
import { AppContext } from "../pages/App";

const NuevoReembolso = () => {
  const { cuenta, afiliado, onUpdateAfiliado } = useContext(AppContext);

  const initialState = !afiliado
    ? {}
    : {
        folio: "",
        descripcion: "",
        monto: "",
        documentos: [],
        opcionPago: {
          metodo: "",
          banco: getBanco(afiliado.body_contacto.banco),
          tipoCuenta: getTipoCuenta(afiliado.body_contacto.tipoCuenta),
          numeroCuenta: afiliado.body_contacto.numeroCuenta
        }
      };

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "SET_FOLIO":
        return {
          ...state,
          folio: payload
        };
      case "SET_DESCRIPCION":
        return {
          ...state,
          descripcion: payload
        };
      case "SET_MONTO":
        return {
          ...state,
          monto: payload
        };
      case "ADD_DOCUMENTO":
        return {
          ...state,
          documentos: [...state.documentos, payload]
        };
      case "REMOVE_DOCUMENTO":
        return {
          ...state,
          documentos: state.documentos.filter(i => i.id !== payload)
        };
      case "CHANGE_MONTO_DOCUMENTO":
        return {
          ...state,
          documentos: state.documentos.map(i =>
            i.id === payload.id ? { ...i, monto: payload.monto } : i
          )
        };
      case "SET_METODO_PAGO":
        return {
          ...state,
          opcionPago: {
            ...state.opcionPago,
            metodo: payload
          }
        };
      case "SET_BANCO":
        return {
          ...state,
          opcionPago: {
            ...state.opcionPago,
            banco: payload,
            bancoError:
              state.opcionPago.metodo === PAYMENT_OPTIONS.transferencia && !payload
                ? "input-error"
                : ""
          }
        };
      case "SET_TIPO_CUENTA":
        return {
          ...state,
          opcionPago: {
            ...state.opcionPago,
            tipoCuenta: payload,
            tipoCuentaError:
              state.opcionPago.metodo === PAYMENT_OPTIONS.transferencia && !payload
                ? "input-error"
                : ""
          }
        };
      case "SET_NUMERO_CUENTA":
        return {
          ...state,
          opcionPago: {
            ...state.opcionPago,
            numeroCuenta: payload,
            numeroCuentaError:
              state.opcionPago.metodo === PAYMENT_OPTIONS.transferencia && payload === ""
                ? "input-error"
                : ""
          }
        };
      case "RESET":
        return { ...initialState };
      default:
        return { ...initialState };
    }
  };

  const [datos, dispatch] = useReducer(reducer, initialState);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const reset = () => {
    dispatch({ type: "RESET" });
    setStep(0);
  };

  const canContinue = () => {
    if (!datos.descripcion || datos.descripcion === "") {
      return false;
    } else if (!datos.monto || datos.monto === "" || datos.monto <= 0) {
      return false;
    } else if (datos.documentos.length < 1) {
      return false;
    } else if (
      datos.opcionPago.metodo !== PAYMENT_OPTIONS.valeVista &&
      datos.opcionPago.metodo !== PAYMENT_OPTIONS.transferencia
    ) {
      return false;
    } else if (
      datos.opcionPago.metodo === PAYMENT_OPTIONS.transferencia &&
      (!datos.opcionPago.banco ||
        !datos.opcionPago.tipoCuenta ||
        datos.opcionPago.numeroCuenta === "")
    ) {
      return false;
    } else return true;
  };

  const createReembolso = async () => {
    let id = null;
    let folio = null;

    try {
      const init = {
        body: {
          afiliadoId: afiliado.id_contacto,
          cuentaId: cuenta.id_cuenta,
          tipoTicketId: cuenta.id_tipo_ticket,
          nombre: `Solicitud de Reembolso`,
          body: {
            asegurado: afiliado,
            beneficiario: afiliado,
            descripcion: datos.descripcion,
            fecha: new Date().toISOString(),
            monto: datos.monto,
            opcionPago: datos.opcionPago,
            documentos: []
          },
          email: afiliado.body_contacto.correo
        }
      };

      const result = await API.post(process.env.REACT_APP_AFILIADOS_API, "/api/solicitudes", init);

      if (result.success && result.solicitud) {
        id = result.solicitud.id_ticket;
        folio = result.solicitud.folio_ticket;
      }
    } catch (err) {
      logger("NuevoReembolso.createReembolso.error", err, "error");
    }

    return { id, folio };
  };

  const sendFiles = async solicitudId => {
    const files = [];

    try {
      const promises = [];

      for (let i = 0; i < datos.documentos.length; i++) {
        const documento = datos.documentos[i];

        const params = {
          contentType: documento.file.type
        };

        const key = `${solicitudId}/${documento.id}.${documento.extension}`;
        const promise = Storage.put(key, documento.file, params);

        promises.push(promise);

        const file = {
          id: documento.id,
          monto: documento.monto,
          name: documento.name,
          key,
          extension: documento.extension,
          size: documento.file.size,
          type: documento.file.type,
          bucket: process.env.REACT_APP_BUCKET
        };

        files.push(file);
      }

      await Promise.all(promises);
    } catch (err) {
      logger("NuevoReembolso.sendFiles.error", err, "error");
    }

    return files;
  };

  const saveFiles = async (solicitudId, files) => {
    try {
      const init = {
        body: {
          solicitudId,
          path: ".documentos",
          value: files
        }
      };

      await API.put(process.env.REACT_APP_AFILIADOS_API, "/api/solicitudes/body", init);
    } catch (err) {
      logger("NuevoReembolso.saveFiles.error", err, "error");
    }
  };

  const saveReembolso = async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    setStep(1);

    try {
      const { id, folio } = await createReembolso();

      if (id) {
        dispatch({ type: "SET_FOLIO", payload: folio });

        const files = await sendFiles(id);

        if (files && files.length > 0) {
          await saveFiles(id, files);
        }

        if (datos.opcionPago.metodo === PAYMENT_OPTIONS.transferencia) {
          await updateBankInfo();
        }
      } else {
        setStep(2);
      }
    } catch (err) {
      logger("NuevoReembolso.saveReembolso.error", err, "error");
      setStep(0);
    }

    setLoading(false);
  };

  const updateBankInfo = async () => {
    try {
      const init = {
        body: {
          id: afiliado.id_contacto,
          nombre: afiliado.nombre_contacto,
          body: {
            ...afiliado.body_contacto,
            cuentaBancaria: {
              sinCuenta: datos.opcionPago.sinCuenta,
              banco: datos.opcionPago.banco ? datos.opcionPago.banco.value : "",
              tipoCuenta: datos.opcionPago.tipoCuenta ? datos.opcionPago.tipoCuenta.value : "",
              numeroCuenta: datos.opcionPago.numeroCuenta
            }
          }
        }
      };

      const result = await API.put(process.env.REACT_APP_AFILIADOS_API, "/api/afiliado", init);

      if (result.success && result.afiliado) {
        onUpdateAfiliado(result.afiliado);
      }
    } catch (err) {
      logger("NuevoReembolso.updateBankInfo.error", err, "error");
    }
  };

  const renderReembolso = () => {
    return (
      <div className="section">
        <div className="documents">
          <h3>Documentos</h3>
          <div className="descripciones">
            <TextField
              className="descripcion"
              label="Descripción"
              placeholder="Ejemplo: Compra tratamiento diabetes"
              value={datos.descripcion}
              inputProps={{
                maxLength: 500
              }}
              InputLabelProps={{
                shrink: true
              }}
              onChange={({ target }) =>
                dispatch({
                  type: "SET_DESCRIPCION",
                  payload: target.value
                })
              }
            />

            <NumberFormat
              className="descripcion"
              value={datos.monto}
              thousandSeparator="."
              decimalSeparator=","
              prefix="$"
              customInput={TextField}
              label="Monto"
              placeholder="Monto de reembolso"
              onValueChange={({ value }) => {
                dispatch({
                  type: "SET_MONTO",
                  payload: value
                });
              }}
              inputProps={{
                maxLength: 11
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>

          <DocumentUploader
            documents={datos.documentos}
            onAdd={document => dispatch({ type: "ADD_DOCUMENTO", payload: document })}
            onMontoChange={(id, monto) =>
              dispatch({ type: "CHANGE_MONTO_DOCUMENTO", payload: { id, monto } })
            }
            onRemove={id => dispatch({ type: "REMOVE_DOCUMENTO", payload: id })}
          />
        </div>
      </div>
    );
  };

  const renderOpcionPago = () => {
    return (
      <div className="section">
        <div className="opcion-pago">
          <h3>Método de pago</h3>
          <div className="metodo">
            <div className="left">
              <FormControl>
                <FormControlLabel
                  control={
                    <Radio
                      color="primary"
                      checked={datos.opcionPago.metodo === PAYMENT_OPTIONS.transferencia}
                      onChange={() =>
                        dispatch({
                          type: "SET_METODO_PAGO",
                          payload: PAYMENT_OPTIONS.transferencia
                        })
                      }
                    />
                  }
                  label="Transferencia bancaria"
                />
                <FormHelperText>
                  Utilizar la siguiente cuenta bancaria para transferir reembolso.
                </FormHelperText>
              </FormControl>
              <Collapse
                in={datos.opcionPago.metodo === PAYMENT_OPTIONS.transferencia}
                timeout="auto"
                unmountOnExit
              >
                <div className="fields">
                  <SelectBanco
                    className="field"
                    disabled={datos.opcionPago.metodo !== PAYMENT_OPTIONS.transferencia}
                    value={datos.opcionPago.banco}
                    onChange={value => dispatch({ type: "SET_BANCO", payload: value })}
                  />

                  <SelectTipoCuenta
                    className="field"
                    disabled={datos.opcionPago.metodo !== PAYMENT_OPTIONS.transferencia}
                    value={datos.opcionPago.tipoCuenta}
                    onChange={value => dispatch({ type: "SET_TIPO_CUENTA", payload: value })}
                  />

                  <TextField
                    className="field"
                    label="Número de cuenta"
                    disabled={datos.opcionPago.metodo !== PAYMENT_OPTIONS.transferencia}
                    value={datos.opcionPago.numeroCuenta}
                    inputProps={{
                      maxLength: 30
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={({ target }) =>
                      dispatch({
                        type: "SET_NUMERO_CUENTA",
                        payload: target.value
                      })
                    }
                  />
                </div>
              </Collapse>
            </div>
            <div className="right">
              <AccountBalanceIcon />
            </div>
          </div>
          <div className="metodo">
            <div className="left">
              <FormControl>
                <FormControlLabel
                  control={
                    <Radio
                      color="primary"
                      checked={datos.opcionPago.metodo === PAYMENT_OPTIONS.valeVista}
                      onChange={() =>
                        dispatch({
                          type: "SET_METODO_PAGO",
                          payload: PAYMENT_OPTIONS.valeVista
                        })
                      }
                    />
                  }
                  label="Vale Vista"
                />
                <FormHelperText>
                  No tengo cuenta bancaria y necesito emisión de Vale Vista para cobrar
                  personalmente.
                </FormHelperText>
              </FormControl>
            </div>
            <div className="right">
              <DescriptionOutlinedIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSolicitud = () => {
    return (
      <>
        <div className="sections">
          {renderReembolso()}
          {renderOpcionPago()}
        </div>
        <div className="buttons">
          <Button
            className="button"
            variant="contained"
            color="primary"
            onClick={saveReembolso}
            disabled={!canContinue()}
          >
            Solicitar reembolso
          </Button>
        </div>
      </>
    );
  };

  const renderComprobante = () => {
    return (
      <>
        <ComprobanteReembolso
          loading={loading}
          id={datos.folio}
          monto={datos.monto}
          opcionPago={datos.opcionPago}
        />

        {!loading && (
          <div className="buttons">
            <Button className="button" variant="contained" color="primary" onClick={reset}>
              Solicitar otro reembolso
            </Button>
          </div>
        )}
      </>
    );
  };

  const renderError = () => {
    return (
      <div className="error-page">
        <div className="title">
          <Typography className="text" component="span">
            Ha ocurrido un error, favor intenta más tarde.
          </Typography>
          <span className="emoji" role="img" aria-label="emoticon">
            😕
          </span>
        </div>
      </div>
    );
  };

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return renderSolicitud();
      case 1:
        return renderComprobante();
      case 2:
        return renderError();
      default:
        return null;
    }
  };

  if (!afiliado) {
    return <Redirect to="/" />;
  }

  return <div className="page nuevo-reembolso">{renderStepContent()}</div>;
};

export default NuevoReembolso;
