import React, { useState, useEffect } from "react";
import dotenv from "dotenv";
import parseISO from "date-fns/parseISO";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import Amplify from "aws-amplify";
import { DateUtils } from "@aws-amplify/core";
import { Authenticator } from "aws-amplify-react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";
import CssBaseline from "@material-ui/core/CssBaseline";
import awsconfig from "../aws-exports";
import logger from "../logger";
import App from "../pages/App";
import CompletarNuevaContrasena from "../pages/CompletarNuevaContrasena";
import ConfirmarRegistro from "../pages/ConfirmarRegistro";
import Login from "../pages/Login";
import Registro from "../pages/Registro";
import RestablecerContrasena from "../pages/RestablecerContrasena";
import ServerError from "../pages/ServerError";
import ErrorBoundary from "./ErrorBoundary";
import Loading from "./Loading";

dotenv.config();

Amplify.configure(awsconfig);

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: "#25aae1",
        contrastText: "#fff"
      },
      secondary: {
        main: "#8dc63f",
        contrastText: "#fff"
      }
    }
  },
  esES
);

const MAX_SIGNV4_OFFSET = 300000;

const Root = () => {
  const [clockSkew, setClockSkew] = useState(null);
  const [isError, setIsError] = useState(false);

  async function setClockOffset() {
    try {
      const result = await fetch(`${process.env.REACT_APP_URL_UTILS}/time`);

      if (result.ok) {
        const data = await result.json();
        const servertime = parseISO(data);

        const timediff = Math.abs(differenceInMilliseconds(new Date(), servertime));

        if (timediff > MAX_SIGNV4_OFFSET) {
          DateUtils.setClockOffset(timediff);
        }

        setClockSkew(timediff);
      } else {
        setClockSkew(-1);
        throw new Error(result.statusText);
      }
    } catch (err) {
      logger("Root.setClockOffset.error", err);
      setIsError(true);
    }
  }

  useEffect(() => {
    setClockOffset();
  }, []);

  if (!Boolean(clockSkew)) return <Loading show />;
  if (isError) return <ServerError />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <Authenticator authState="signUp" hideDefault>
          <Login override="SignIn" />
          <Registro override="SignUp" />
          <ConfirmarRegistro override="ConfirmSignUp" />
          <RestablecerContrasena override="ForgotPassword" />
          <CompletarNuevaContrasena override="RequireNewPassword" />
          <App />
        </Authenticator>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default Root;
