import React, { useState, useReducer, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import {
  formatRut,
  unformatRut,
  rutEsValido,
  toTitleCase,
  nombreEsValido,
  formatCelular,
  unformatCelular,
  validateEmail
} from "../../functions";
import logger from "../../logger";
import { AppContext } from "../App";
import SelectBanco from "../../components/SelectBanco";
import SelectTipoCuenta from "../../components/SelectTipoCuenta";

const Wizard = () => {
  const { afiliado, authData, onUpdateAfiliado } = useContext(AppContext);

  const steps = [
    { label: "Datos Personales" },
    { label: "Contacto" },
    { label: "Banco" },
    { label: "Confirmación" },
    { label: "¡Listo!" }
  ];

  const initialState = {
    datosPersonales: {
      rut: "",
      primerNombre: "",
      segundoNombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      fechaNacimiento: ""
    },
    contacto: {
      email: authData.attributes.email,
      telefono: "",
      comuna: "",
      calle: "",
      numero: "",
      depto: ""
    },
    banco: {
      sinCuenta: false,
      banco: null,
      tipoCuenta: null,
      numeroCuenta: ""
    }
  };

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "SET_RUT":
        return {
          ...state,
          datosPersonales: {
            ...state.datosPersonales,
            rut: unformatRut(payload),
            rutError: rutEsValido(payload) ? "" : "input-error"
          }
        };
      case "SET_PRIMER_NOMBRE":
        return {
          ...state,
          datosPersonales: {
            ...state.datosPersonales,
            primerNombre: toTitleCase(payload),
            primerNombreError: nombreEsValido(payload) && payload.length > 1 ? "" : "input-error"
          }
        };
      case "SET_SEGUNDO_NOMBRE":
        return {
          ...state,
          datosPersonales: {
            ...state.datosPersonales,
            segundoNombre: toTitleCase(payload),
            segundoNombreError:
              payload.length > 0 ? (nombreEsValido(payload) ? "" : "input-error") : ""
          }
        };
      case "SET_PRIMER_APELLIDO":
        return {
          ...state,
          datosPersonales: {
            ...state.datosPersonales,
            apellidoPaterno: toTitleCase(payload),
            apellidoPaternoError: nombreEsValido(payload) && payload.length > 1 ? "" : "input-error"
          }
        };
      case "SET_SEGUNDO_APELLIDO":
        return {
          ...state,
          datosPersonales: {
            ...state.datosPersonales,
            apellidoMaterno: toTitleCase(payload),
            apellidoMaternoError:
              payload.length > 0 ? (nombreEsValido(payload) ? "" : "input-error") : ""
          }
        };
      case "SET_FECHA_NACIMIENTO":
        return {
          ...state,
          datosPersonales: {
            ...state.datosPersonales,
            fechaNacimiento: payload,
            fechaNacimientoError: payload.length === 10 ? "" : "input-error"
          }
        };
      case "SET_EMAIL":
        return {
          ...state,
          contacto: {
            ...state.contacto,
            email: payload.toLowerCase(),
            emailError: validateEmail(payload) ? "" : "input-error"
          }
        };
      case "SET_TELEFONO":
        return {
          ...state,
          contacto: {
            ...state.contacto,
            telefono: unformatCelular(payload),
            telefonoError: unformatCelular(payload).length === 8 ? "" : "input-error"
          }
        };
      case "SET_COMUNA":
        return {
          ...state,
          contacto: {
            ...state.contacto,
            comuna: toTitleCase(payload)
          }
        };
      case "SET_CALLE":
        return {
          ...state,
          contacto: {
            ...state.contacto,
            calle: toTitleCase(payload)
          }
        };
      case "SET_NUMERO":
        return {
          ...state,
          contacto: {
            ...state.contacto,
            numero: payload
          }
        };
      case "SET_DEPTO":
        return {
          ...state,
          contacto: {
            ...state.contacto,
            depto: toTitleCase(payload)
          }
        };
      case "SET_BANCO":
        return {
          ...state,
          banco: {
            ...state.banco,
            banco: payload
          }
        };
      case "SET_TIPO_CUENTA":
        return {
          ...state,
          banco: {
            ...state.banco,
            tipoCuenta: payload
          }
        };
      case "SET_NUMERO_CUENTA":
        return {
          ...state,
          banco: {
            ...state.banco,
            numeroCuenta: payload
          }
        };
      case "SET_SIN_CUENTA":
        return {
          ...state,
          banco: {
            ...state.banco,
            banco: initialState.banco.banco,
            tipoCuenta: initialState.banco.tipoCuenta,
            numeroCuenta: initialState.banco.numeroCuenta,
            sinCuenta: !state.banco.sinCuenta
          }
        };
      default:
        return { ...initialState };
    }
  };

  const [step, setStep] = useState(0);
  const [updated, setUpdated] = useState(null);
  const [datos, dispatch] = useReducer(reducer, initialState);

  const fieldVariant = "standard";

  const guardar = async () => {
    try {
      const userInfo = await Auth.currentUserInfo();

      const nombre = `${datos.datosPersonales.primerNombre}${
        datos.datosPersonales.segundoNombre ? ` ${datos.datosPersonales.segundoNombre}` : ""
      } ${datos.datosPersonales.apellidoPaterno}${
        datos.datosPersonales.apellidoMaterno ? ` ${datos.datosPersonales.apellidoMaterno}` : ""
      }`;

      const init = {
        body: {
          userId: authData.username,
          nombre: nombre,
          body: {
            identityId: userInfo.id,
            rut: datos.datosPersonales.rut,
            primerNombre: datos.datosPersonales.primerNombre,
            segundoNombre: datos.datosPersonales.segundoNombre,
            apellidoPaterno: datos.datosPersonales.apellidoPaterno,
            apellidoMaterno: datos.datosPersonales.apellidoMaterno,
            fechaNacimiento: datos.datosPersonales.fechaNacimiento,
            correo: datos.contacto.email,
            telefono: unformatCelular(datos.contacto.telefono),
            calle: datos.contacto.calle,
            numero: datos.contacto.numero,
            depto: datos.contacto.depto,
            comuna: datos.contacto.comuna,
            sinCuenta: datos.banco.sinCuenta,
            banco: datos.banco.banco ? datos.banco.banco.value : "",
            tipoCuenta: datos.banco.tipoCuenta ? datos.banco.tipoCuenta.value : "",
            numeroCuenta: datos.banco.numeroCuenta
          }
        }
      };

      const result = await API.post(process.env.REACT_APP_AFILIADOS_API, "/api/afiliado", init);

      if (result.success && result.afiliado) {
        setUpdated(result.afiliado);
      }

      window.scrollTo(0, 0);
      setStep(p => p + 1);
    } catch (err) {
      logger("Perfil.wizard.guardar.error", err, "error");
    }
  };

  const anterior = () => {
    window.scrollTo(0, 0);
    setStep(p => p - 1);
  };

  const siguiente = () => {
    window.scrollTo(0, 0);
    setStep(p => p + 1);
  };

  const canContinue = () => {
    switch (step) {
      case 0:
        return (
          rutEsValido(datos.datosPersonales.rut) &&
          datos.datosPersonales.primerNombre.length > 1 &&
          nombreEsValido(datos.datosPersonales.primerNombre) &&
          (datos.datosPersonales.segundoNombre.length === 0 ||
            (datos.datosPersonales.segundoNombre.length > 0 &&
              nombreEsValido(datos.datosPersonales.segundoNombre))) &&
          datos.datosPersonales.apellidoPaterno.length > 1 &&
          nombreEsValido(datos.datosPersonales.apellidoPaterno) &&
          (datos.datosPersonales.apellidoMaterno.length === 0 ||
            (datos.datosPersonales.apellidoMaterno.length > 0 &&
              nombreEsValido(datos.datosPersonales.apellidoMaterno))) &&
          datos.datosPersonales.fechaNacimiento.length === 10
        );
      case 1:
        return (
          validateEmail(datos.contacto.email) &&
          unformatCelular(datos.contacto.telefono).length === 8
        );
      case 2:
        return (
          datos.banco.sinCuenta ||
          (datos.banco.banco !== "" &&
            datos.banco.tipoCuenta !== "" &&
            datos.banco.numeroCuenta !== "")
        );
      default:
        return false;
    }
  };

  const renderDatosPersonales = () => (
    <>
      <TextField
        className={`field ${datos.datosPersonales.rutError}`}
        label="RUT"
        placeholder="22.222.222-K"
        variant={fieldVariant}
        value={formatRut(datos.datosPersonales.rut)}
        inputProps={{
          maxLength: 12
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_RUT", payload: target.value })}
      />

      <TextField
        className={`field ${datos.datosPersonales.primerNombreError}`}
        label="Primer nombre *"
        variant={fieldVariant}
        value={datos.datosPersonales.primerNombre}
        inputProps={{
          maxLength: 255
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_PRIMER_NOMBRE", payload: target.value })}
      />

      <TextField
        className={`field ${datos.datosPersonales.segundoNombreError}`}
        label="Segundo nombre"
        variant={fieldVariant}
        value={datos.datosPersonales.segundoNombre}
        inputProps={{
          maxLength: 255
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_SEGUNDO_NOMBRE", payload: target.value })}
      />

      <TextField
        className={`field ${datos.datosPersonales.apellidoPaternoError}`}
        label="Apellido paterno *"
        variant={fieldVariant}
        value={datos.datosPersonales.apellidoPaterno}
        inputProps={{
          maxLength: 255
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_PRIMER_APELLIDO", payload: target.value })}
      />

      <TextField
        className={`field ${datos.datosPersonales.apellidoMaternoError}`}
        label="Apellido materno"
        variant={fieldVariant}
        value={datos.datosPersonales.apellidoMaterno}
        inputProps={{
          maxLength: 255
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_SEGUNDO_APELLIDO", payload: target.value })}
      />

      <TextField
        className={`field ${datos.datosPersonales.fechaNacimientoError}`}
        label="Fecha de nacimiento *"
        variant={fieldVariant}
        value={datos.datosPersonales.fechaNacimiento}
        type="date"
        inputProps={{
          maxLength: 10
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_FECHA_NACIMIENTO", payload: target.value })}
      />
    </>
  );

  const renderContacto = () => (
    <>
      <TextField
        className={`field ${datos.contacto.emailError}`}
        label="Email *"
        variant={fieldVariant}
        value={datos.contacto.email}
        inputProps={{
          maxLength: 255
        }}
        InputLabelProps={{
          shrink: true
        }}
        disabled
      />

      <TextField
        className={`field ${datos.contacto.telefonoError}`}
        label="Teléfono Celular *"
        variant={fieldVariant}
        value={formatCelular(datos.contacto.telefono)}
        inputProps={{
          maxLength: 15
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_TELEFONO", payload: target.value })}
      />

      <TextField
        className={`field ${datos.contacto.comunaError}`}
        label="Comuna"
        variant={fieldVariant}
        value={datos.contacto.comuna}
        inputProps={{
          maxLength: 255
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_COMUNA", payload: target.value })}
      />

      <TextField
        className={`field ${datos.contacto.calleError}`}
        label="Calle"
        variant={fieldVariant}
        value={datos.contacto.calle}
        inputProps={{
          maxLength: 255
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_CALLE", payload: target.value })}
      />

      <TextField
        className={`field ${datos.contacto.numeroError}`}
        label="Número"
        variant={fieldVariant}
        value={datos.contacto.numero}
        inputProps={{
          maxLength: 255
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_NUMERO", payload: target.value })}
      />

      <TextField
        className={`field ${datos.contacto.deptoError}`}
        label="Edificio / Bloque / Departamento"
        variant={fieldVariant}
        value={datos.contacto.depto}
        inputProps={{
          maxLength: 255
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_DEPTO", payload: target.value })}
      />
    </>
  );

  const renderBanco = () => (
    <>
      <SelectBanco
        className={`field ${datos.banco.bancoError}`}
        variant={fieldVariant}
        disabled={datos.banco.sinCuenta === "true" || datos.banco.sinCuenta === true}
        value={datos.banco.banco}
        onChange={value => dispatch({ type: "SET_BANCO", payload: value })}
      />

      <SelectTipoCuenta
        className={`field ${datos.banco.tipoCuentaError}`}
        variant={fieldVariant}
        disabled={datos.banco.sinCuenta === "true" || datos.banco.sinCuenta === true}
        value={datos.banco.tipoCuenta}
        onChange={value => dispatch({ type: "SET_TIPO_CUENTA", payload: value })}
      />

      <TextField
        className={`field ${datos.banco.numeroCuentaError}`}
        label="Número de cuenta"
        variant={fieldVariant}
        disabled={datos.banco.sinCuenta === "true" || datos.banco.sinCuenta === true}
        value={datos.banco.numeroCuenta}
        inputProps={{
          maxLength: 255
        }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={({ target }) => dispatch({ type: "SET_NUMERO_CUENTA", payload: target.value })}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={datos.banco.sinCuenta === "true" || datos.banco.sinCuenta === true}
            onChange={() => dispatch({ type: "SET_SIN_CUENTA" })}
          />
        }
        label="No tengo cuenta bancaria"
      />
    </>
  );

  const renderConfirmacion = () => (
    <>
      <h2>Revisión</h2>
      <div className="revision">
        <div className="column">
          <strong>Datos personales</strong>
          <div>{formatRut(datos.datosPersonales.rut)}</div>
          <div>
            {`${datos.datosPersonales.primerNombre}${
              datos.datosPersonales.segundoNombre ? ` ${datos.datosPersonales.segundoNombre}` : ""
            } ${datos.datosPersonales.apellidoPaterno}${
              datos.datosPersonales.apellidoMaterno
                ? ` ${datos.datosPersonales.apellidoMaterno}`
                : ""
            }`}
          </div>
          <div>{datos.datosPersonales.fechaNacimiento}</div>
        </div>

        <div className="column">
          <strong>Contacto</strong>
          <div>{datos.contacto.email}</div>
          <div>{formatCelular(datos.contacto.telefono)}</div>
          <div>
            {`${datos.contacto.calle ? datos.contacto.calle : ""}${
              datos.contacto.numero ? `, ${datos.contacto.numero}` : ""
            }`}
          </div>
          <div>{datos.contacto.comuna}</div>
          <div>{datos.contacto.depto}</div>
        </div>

        <div className="column">
          <strong>Banco</strong>
          {datos.banco.banco && <div>{datos.banco.banco.label}</div>}
          {datos.banco.tipoCuenta && <div>{datos.banco.tipoCuenta.label}</div>}
          {datos.banco.numeroCuenta && <div>{datos.banco.numeroCuenta}</div>}
          {datos.banco.sinCuenta && <div>Sin cuenta bancaria</div>}
        </div>
      </div>
      <Button
        className="button"
        variant="contained"
        color="primary"
        onClick={guardar}
        style={{ width: "200px" }}
      >
        Guardar
      </Button>
    </>
  );

  const renderListo = () => (
    <div className="listo">
      <h1>¡Listo!</h1>
      <h2>Ahora puedes solicitar reembolsos cuando estimes conveniente</h2>
      <Link
        to="/nuevo-reembolso"
        className="button"
        style={{ width: "300px" }}
        onClick={() => onUpdateAfiliado(updated)}
      >
        quiero solicitar un reembolso
      </Link>
    </div>
  );

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return renderDatosPersonales();
      case 1:
        return renderContacto();
      case 2:
        return renderBanco();
      case 3:
        return renderConfirmacion();
      case 4:
        return renderListo();
      default:
        return null;
    }
  };

  if (afiliado) {
    return <Redirect to="/" />;
  }

  return (
    <div className="wizard-perfil">
      <ul className="steps">
        {steps.map((s, i) => (
          <li
            key={`step-${i}`}
            className={step === i ? "active" : ""}
            style={{ width: `calc(100% / ${steps.length})` }}
          >
            {i + 1}
            <span className="label">. {s.label}</span>
          </li>
        ))}
      </ul>

      <div className="step">{renderStepContent()}</div>

      <div className="buttons">
        <Button
          className="button"
          variant="contained"
          color="default"
          disabled={step <= 0}
          onClick={anterior}
        >
          Atrás
        </Button>
        {step < steps.length - 2 && (
          <Button
            className="button"
            variant="contained"
            color="primary"
            onClick={siguiente}
            disabled={!canContinue()}
          >
            Siguiente
          </Button>
        )}
      </div>
    </div>
  );
};

export default Wizard;
