import React from "react";
import NumberFormat from "react-number-format";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const DocumentUploader = ({ documents, onAdd, onRemove, onMontoChange }) => {
  const agregarDocumento = target => {
    if (target.files.length > 0) {
      const file = target.files[0];
      const meta = file.name.split(".");
      const extension = meta[meta.length - 1];
      const name = meta.slice(0, meta.length - 1).join(".");

      const payload = {
        id: Math.floor(new Date().valueOf() * Math.random()),
        name,
        extension,
        file,
        filePreview: file.type === "application/pdf" ? undefined : URL.createObjectURL(file)
      };

      onAdd(payload);
    }
  };

  return (
    <div className="documents-list">
      <div className="add-document">
        <input
          accept="image/*, application/pdf"
          style={{ display: "none" }}
          id="document-button-file"
          disabled={false}
          type="file"
          onChange={({ target }) => agregarDocumento(target)}
        />
        <Button
          className="button"
          variant="contained"
          color="primary"
          component="label"
          htmlFor="document-button-file"
        >
          Agregar documento
        </Button>
      </div>

      {documents.map(document => (
        <div key={`document-${document.id}`} className="document-item">
          {document.file.type === "application/pdf" ? (
            <Avatar variant="square" title={document.name}>
              <PictureAsPdfIcon />
            </Avatar>
          ) : (
            <Avatar variant="square" src={document.filePreview} title={document.name} />
          )}
          <NumberFormat
            value={document.monto}
            thousandSeparator="."
            decimalSeparator=","
            prefix="$"
            customInput={TextField}
            className="control"
            label="Monto de documento"
            placeholder="Ej: $25.000"
            size="small"
            variant="standard"
            onValueChange={({ _, value }) => onMontoChange(document.id, parseInt(value))}
            inputProps={{
              maxLength: 8
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <Tooltip placement="right" title="Eliminar">
            <IconButton onClick={() => onRemove(document.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default DocumentUploader;
