import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const bancos = [
  { label: "Banco BICE", value: "BANCO_BICE" },
  { label: "Banco Consorcio", value: "BANCO_CONSORCIO" },
  { label: "Banco Corpbanca", value: "BANCO_CORPBANCA" },
  {
    label: "Banco Crédito e Inversiones",
    value: "BANCO_CREDITO_E_INVERSIONES"
  },
  { label: "Banco Estado", value: "BANCO_ESTADO" },
  { label: "Banco Falabella", value: "BANCO_FALABELLA" },
  { label: "Banco Internacional", value: "BANCO_INTERNACIONAL" },
  { label: "Banco París", value: "BANCO_PARIS" },
  { label: "Banco Ripley", value: "BANCO_RIPLEY" },
  { label: "Banco Santander", value: "BANCO_SANTANDER" },
  { label: "Banco Santander Banefe", value: "BANCO_SANTANDER_BANEFE" },
  { label: "Banco Security", value: "BANCO_SECURITY" },
  { label: "Banco de Chile / Edwards-Citi", value: "BANCO_DE_CHILE" },
  { label: "Banco del Desarrollo", value: "BANCO_DEL_DESARROLLO" },
  { label: "Coopeuch", value: "COOPEUCH" },
  { label: "HSBC Bank", value: "HSBC_BANK" },
  { label: "Itaú", value: "ITAU" },
  { label: "Rabobank", value: "RABOBANK" },
  { label: "Scotiabank", value: "SCOTIABANK" },
  { label: "Scotiabank Azúl", value: "SCOTIABANK_AZUL" }
];

const SelectBanco = ({
  id = "--select-banco",
  className,
  disabled,
  variant,
  required,
  placeholder,
  size,
  error,
  helperText,
  value,
  onChange = () => {},
  onBlur = () => {}
}) => {
  return (
    <Autocomplete
      id={id}
      className={className}
      disabled={disabled}
      options={bancos}
      getOptionLabel={option => (option.label ? option.label : option)}
      value={value}
      onChange={(_, value) => onChange(value)}
      onBlur={onBlur}
      renderInput={params => (
        <TextField
          {...params}
          required={required}
          fullWidth
          label="Banco"
          placeholder={placeholder}
          size={size}
          variant={variant}
          InputLabelProps={{
            shrink: Boolean(value) || Boolean(placeholder) || document.activeElement.id === id
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export const getBanco = value => bancos.filter(b => b.value === value)[0] || null;

export default SelectBanco;
