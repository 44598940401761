import React, { useState, useReducer, useContext } from "react";
import { Redirect } from "react-router-dom";
import { API } from "aws-amplify";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import {
  formatRut,
  rutEsValido,
  toTitleCase,
  nombreEsValido,
  formatCelular,
  unformatCelular,
  validateEmail,
  isAdult
} from "../../functions";
import logger from "../../logger";
import SelectBanco, { getBanco } from "../../components/SelectBanco";
import SelectTipoCuenta, { getTipoCuenta } from "../../components/SelectTipoCuenta";
import { AppContext } from "../App";

const Ver = () => {
  const { afiliado, onUpdateAfiliado } = useContext(AppContext);

  const initialState = !afiliado
    ? {}
    : {
        datosPersonales: {
          rut: afiliado.body_contacto.rut || "",
          primerNombre: afiliado.body_contacto.primerNombre || "",
          segundoNombre: afiliado.body_contacto.segundoNombre || "",
          apellidoPaterno: afiliado.body_contacto.apellidoPaterno || "",
          apellidoMaterno: afiliado.body_contacto.apellidoMaterno || "",
          fechaNacimiento: afiliado.body_contacto.fechaNacimiento || ""
        },
        contacto: {
          email: afiliado.body_contacto.correo || "",
          telefono: afiliado.body_contacto.telefono || "",
          comuna: afiliado.body_contacto.comuna || "",
          calle: afiliado.body_contacto.calle || "",
          numero: afiliado.body_contacto.numero || "",
          depto: afiliado.body_contacto.depto || ""
        },
        banco: {
          sinCuenta: afiliado.body_contacto.sinCuenta || false,
          banco: getBanco(afiliado.body_contacto.banco) || null,
          tipoCuenta: getTipoCuenta(afiliado.body_contacto.tipoCuenta) || null,
          numeroCuenta: afiliado.body_contacto.numeroCuenta || ""
        }
      };

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "SET_PRIMER_NOMBRE":
        return {
          ...state,
          datosPersonales: {
            ...state.datosPersonales,
            primerNombre: toTitleCase(payload),
            primerNombreError: nombreEsValido(payload) && payload.length > 1 ? "" : "input-error"
          }
        };
      case "SET_SEGUNDO_NOMBRE":
        return {
          ...state,
          datosPersonales: {
            ...state.datosPersonales,
            segundoNombre: toTitleCase(payload),
            segundoNombreError:
              payload.length > 0 ? (nombreEsValido(payload) ? "" : "input-error") : ""
          }
        };
      case "SET_PRIMER_APELLIDO":
        return {
          ...state,
          datosPersonales: {
            ...state.datosPersonales,
            apellidoPaterno: toTitleCase(payload),
            apellidoPaternoError: nombreEsValido(payload) && payload.length > 1 ? "" : "input-error"
          }
        };
      case "SET_SEGUNDO_APELLIDO":
        return {
          ...state,
          datosPersonales: {
            ...state.datosPersonales,
            apellidoMaterno: toTitleCase(payload),
            apellidoMaternoError:
              payload.length > 0 ? (nombreEsValido(payload) ? "" : "input-error") : ""
          }
        };
      case "SET_FECHA_NACIMIENTO":
        return {
          ...state,
          datosPersonales: {
            ...state.datosPersonales,
            fechaNacimiento: payload,
            fechaNacimientoError:
              payload.length === 10 && isAdult(Date.parse(payload)) ? "" : "input-error"
          }
        };
      case "SET_TELEFONO":
        return {
          ...state,
          contacto: {
            ...state.contacto,
            telefono: unformatCelular(payload),
            telefonoError: unformatCelular(payload).length === 8 ? "" : "input-error"
          }
        };
      case "SET_COMUNA":
        return {
          ...state,
          contacto: {
            ...state.contacto,
            comuna: toTitleCase(payload)
          }
        };
      case "SET_CALLE":
        return {
          ...state,
          contacto: {
            ...state.contacto,
            calle: toTitleCase(payload)
          }
        };
      case "SET_NUMERO":
        return {
          ...state,
          contacto: {
            ...state.contacto,
            numero: payload
          }
        };
      case "SET_DEPTO":
        return {
          ...state,
          contacto: {
            ...state.contacto,
            depto: toTitleCase(payload)
          }
        };
      case "SET_BANCO":
        return {
          ...state,
          banco: {
            ...state.banco,
            banco: payload
          }
        };
      case "SET_TIPO_CUENTA":
        return {
          ...state,
          banco: {
            ...state.banco,
            tipoCuenta: payload
          }
        };
      case "SET_NUMERO_CUENTA":
        return {
          ...state,
          banco: {
            ...state.banco,
            numeroCuenta: payload
          }
        };
      case "SET_SIN_CUENTA":
        return {
          ...state,
          banco: {
            ...state.banco,
            banco: initialState.banco.banco,
            tipoCuenta: initialState.banco.tipoCuenta,
            numeroCuenta: initialState.banco.numeroCuenta,
            sinCuenta: !state.banco.sinCuenta
          }
        };
      case "RESET_DATOS_PERSONALES":
        return {
          ...state,
          datosPersonales: initialState.datosPersonales
        };
      case "RESET_CONTACTO":
        return {
          ...state,
          contacto: initialState.contacto
        };
      case "RESET_BANCO":
        return {
          ...state,
          banco: initialState.banco
        };
      default:
        return { ...initialState };
    }
  };

  const [editPersonal, setEditPersonal] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [editBank, setEditBank] = useState(false);
  const [datos, dispatch] = useReducer(reducer, initialState);
  const fieldVariant = "standard";
  const [loadingPersonal, setLoadingPersonal] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);
  const [loadingBank, setLoadingBank] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleCancelEditPersonal = () => {
    dispatch({ type: "RESET_DATOS_PERSONALES" });
    handleEditPersonal();
  };

  const handleEditPersonal = () => {
    setEditPersonal(!editPersonal);
    setEditContact(false);
    setEditBank(false);
    setError(false);
  };

  const handleCancelEditContact = () => {
    dispatch({ type: "RESET_CONTACTO" });
    handleEditContact();
  };

  const handleEditContact = () => {
    setEditContact(!editContact);
    setEditPersonal(false);
    setEditBank(false);
    setError(false);
  };

  const handleCancelEditBank = () => {
    dispatch({ type: "RESET_BANCO" });
    handleEditBank();
  };

  const handleEditBank = () => {
    setEditBank(!editBank);
    setEditPersonal(false);
    setEditContact(false);
    setError(false);
  };

  const validatePersonal = () => {
    return (
      rutEsValido(datos.datosPersonales.rut) &&
      datos.datosPersonales.primerNombre.length > 1 &&
      nombreEsValido(datos.datosPersonales.primerNombre) &&
      (datos.datosPersonales.segundoNombre.length === 0 ||
        (datos.datosPersonales.segundoNombre.length > 0 &&
          nombreEsValido(datos.datosPersonales.segundoNombre))) &&
      datos.datosPersonales.apellidoPaterno.length > 1 &&
      nombreEsValido(datos.datosPersonales.apellidoPaterno) &&
      (datos.datosPersonales.apellidoMaterno.length === 0 ||
        (datos.datosPersonales.apellidoMaterno.length > 0 &&
          nombreEsValido(datos.datosPersonales.apellidoMaterno))) &&
      datos.datosPersonales.fechaNacimiento.length === 10 &&
      isAdult(Date.parse(datos.datosPersonales.fechaNacimiento))
    );
  };

  const validateContact = () => {
    return validateEmail(datos.contacto.email);
  };

  const validateBank = () => {
    return (
      datos.banco.sinCuenta ||
      (datos.banco.banco !== "" && datos.banco.tipoCuenta !== "" && datos.banco.numeroCuenta !== "")
    );
  };

  const save = async body => {
    const nombre = `${datos.datosPersonales.primerNombre}${
      datos.datosPersonales.segundoNombre ? ` ${datos.datosPersonales.segundoNombre}` : ""
    } ${datos.datosPersonales.apellidoPaterno}${
      datos.datosPersonales.apellidoMaterno ? ` ${datos.datosPersonales.apellidoMaterno}` : ""
    }`;

    const init = {
      body: {
        id: afiliado.id_contacto,
        nombre,
        body
      }
    };

    const result = await API.put(process.env.REACT_APP_AFILIADOS_API, "/api/afiliado", init);

    if (result.success && result.afiliado) {
      return result.afiliado;
    } else {
      throw result;
    }
  };

  const savePersonal = async () => {
    setLoadingPersonal(true);
    try {
      const body = {
        ...afiliado.body_contacto,
        rut: datos.datosPersonales.rut,
        primerNombre: datos.datosPersonales.primerNombre,
        segundoNombre: datos.datosPersonales.segundoNombre,
        apellidoPaterno: datos.datosPersonales.apellidoPaterno,
        apellidoMaterno: datos.datosPersonales.apellidoMaterno,
        fechaNacimiento: datos.datosPersonales.fechaNacimiento
      };

      const updated = await save(body);

      onUpdateAfiliado(updated);
      setEditPersonal(false);
      setError(false);
    } catch (err) {
      logger("Perfil.editar.datos.error", err, "error");
      setErrorMsg("Ocurrió un problema al guardar los datos");
      setError(true);
    }

    setLoadingPersonal(false);
  };

  const saveContact = async () => {
    setLoadingContact(true);
    try {
      const body = {
        ...afiliado.body_contacto,
        telefono: unformatCelular(datos.contacto.telefono),
        calle: datos.contacto.calle,
        numero: datos.contacto.numero,
        depto: datos.contacto.depto,
        comuna: datos.contacto.comuna
      };

      const updated = await save(body);

      onUpdateAfiliado(updated);
      setEditContact(false);
      setError(false);
    } catch (err) {
      logger("Perfil.editar.contacto.error", err, "error");
      setErrorMsg("Ocurrió un problema al guardar los datos");
      setError(true);
    }

    setLoadingContact(false);
  };

  const saveBank = async () => {
    setLoadingBank(true);
    try {
      const body = {
        ...afiliado.body_contacto,
        sinCuenta: datos.banco.sinCuenta,
        banco: datos.banco.banco.value,
        tipoCuenta: datos.banco.tipoCuenta.value,
        numeroCuenta: datos.banco.numeroCuenta
      };

      const updated = await save(body);

      onUpdateAfiliado(updated);
      setEditBank(false);
      setError(false);
    } catch (err) {
      logger("Perfil.editar.cuentaBancaria.error", err, "error");
      setErrorMsg("Ocurrió un problema al guardar los datos");
      setError(true);
    }

    setLoadingBank(false);
  };

  const renderPersonal = () => {
    return editPersonal ? (
      <div className="datos-editar">
        <label className="mb-4 datos-editar-titulo">Datos personales</label>
        <TextField
          className="field"
          label="RUT"
          placeholder="22.222.222-K"
          variant={fieldVariant}
          value={formatRut(datos.datosPersonales.rut)}
          inputProps={{
            maxLength: 12
          }}
          InputLabelProps={{
            shrink: true
          }}
          disabled
        />

        <TextField
          className={`field ${datos.datosPersonales.primerNombreError}`}
          label="Primer nombre *"
          variant={fieldVariant}
          value={datos.datosPersonales.primerNombre}
          inputProps={{
            maxLength: 255
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={({ target }) => dispatch({ type: "SET_PRIMER_NOMBRE", payload: target.value })}
        />

        <TextField
          className={`field ${datos.datosPersonales.segundoNombreError}`}
          label="Segundo nombre"
          variant={fieldVariant}
          value={datos.datosPersonales.segundoNombre}
          inputProps={{
            maxLength: 255
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={({ target }) => dispatch({ type: "SET_SEGUNDO_NOMBRE", payload: target.value })}
        />

        <TextField
          className={`field ${datos.datosPersonales.apellidoPaternoError}`}
          label="Apellido paterno *"
          variant={fieldVariant}
          value={datos.datosPersonales.apellidoPaterno}
          inputProps={{
            maxLength: 255
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={({ target }) =>
            dispatch({ type: "SET_PRIMER_APELLIDO", payload: target.value })
          }
        />

        <TextField
          className={`field ${datos.datosPersonales.apellidoMaternoError}`}
          label="Apellido materno"
          variant={fieldVariant}
          value={datos.datosPersonales.apellidoMaterno}
          inputProps={{
            maxLength: 255
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={({ target }) =>
            dispatch({ type: "SET_SEGUNDO_APELLIDO", payload: target.value })
          }
        />

        <TextField
          className={`field ${datos.datosPersonales.fechaNacimientoError}`}
          label="Fecha de nacimiento *"
          variant={fieldVariant}
          value={datos.datosPersonales.fechaNacimiento}
          type="date"
          inputProps={{
            maxLength: 10
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={({ target }) =>
            dispatch({ type: "SET_FECHA_NACIMIENTO", payload: target.value })
          }
        />
        <div className="datos-buttons">
          {loadingPersonal ? (
            <Button className="button" variant="contained" color="primary">
              <CircularProgress size={20} />
            </Button>
          ) : (
            <Button
              className="button"
              variant="contained"
              color="primary"
              disabled={!validatePersonal()}
              onClick={savePersonal}
            >
              Actualizar
            </Button>
          )}
          <Button
            className="button"
            variant="contained"
            color="default"
            onClick={handleCancelEditPersonal}
          >
            Cancelar
          </Button>
        </div>
      </div>
    ) : (
      <dl>
        <dt className="mb-4">
          Datos personales{" "}
          <IconButton aria-label="edit" onClick={handleEditPersonal}>
            <EditIcon />
          </IconButton>
        </dt>
        <dd>{formatRut(datos.datosPersonales.rut)}</dd>
        <dd>
          {datos.datosPersonales.primerNombre}
          {datos.datosPersonales.segundoNombre && ` ${datos.datosPersonales.segundoNombre}`}{" "}
          {datos.datosPersonales.apellidoPaterno}
          {datos.datosPersonales.apellidoMaterno && ` ${datos.datosPersonales.apellidoMaterno}`}
        </dd>

        <dd>{datos.datosPersonales.fecha_nacimiento}</dd>
      </dl>
    );
  };

  const renderContact = () => {
    return editContact ? (
      <div className="datos-editar">
        <label className="mb-4 datos-editar-titulo">Contacto</label>
        <TextField
          className="field"
          label="Email *"
          variant={fieldVariant}
          value={datos.contacto.email}
          inputProps={{
            maxLength: 255
          }}
          InputLabelProps={{
            shrink: true
          }}
          disabled
        />

        <TextField
          className={`field ${datos.contacto.telefonoError}`}
          label="Teléfono Celular *"
          variant={fieldVariant}
          value={formatCelular(datos.contacto.telefono)}
          inputProps={{
            maxLength: 15
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={({ target }) => dispatch({ type: "SET_TELEFONO", payload: target.value })}
        />

        <TextField
          className={`field ${datos.contacto.comunaError}`}
          label="Comuna"
          variant={fieldVariant}
          value={datos.contacto.comuna}
          inputProps={{
            maxLength: 255
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={({ target }) => dispatch({ type: "SET_COMUNA", payload: target.value })}
        />

        <TextField
          className={`field ${datos.contacto.calleError}`}
          label="Calle"
          variant={fieldVariant}
          value={datos.contacto.calle}
          inputProps={{
            maxLength: 255
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={({ target }) => dispatch({ type: "SET_CALLE", payload: target.value })}
        />

        <TextField
          className={`field ${datos.contacto.numeroError}`}
          label="Número"
          variant={fieldVariant}
          value={datos.contacto.numero}
          inputProps={{
            maxLength: 255
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={({ target }) => dispatch({ type: "SET_NUMERO", payload: target.value })}
        />

        <TextField
          className={`field ${datos.contacto.deptoError}`}
          label="Edificio / Bloque / Departamento"
          variant={fieldVariant}
          value={datos.contacto.depto}
          inputProps={{
            maxLength: 255
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={({ target }) => dispatch({ type: "SET_DEPTO", payload: target.value })}
        />
        <div className="datos-buttons">
          {loadingContact ? (
            <Button className="button" variant="contained" color="primary">
              <CircularProgress size={20} />
            </Button>
          ) : (
            <Button
              className="button"
              variant="contained"
              color="primary"
              disabled={!validateContact()}
              onClick={saveContact}
            >
              Actualizar
            </Button>
          )}
          <Button
            className="button"
            variant="contained"
            color="default"
            onClick={handleCancelEditContact}
          >
            Cancelar
          </Button>
        </div>
      </div>
    ) : (
      <dl>
        <dt className="mb-4">
          Contacto{" "}
          <IconButton aria-label="edit" onClick={handleEditContact}>
            <EditIcon />
          </IconButton>
        </dt>
        <dd>{datos.contacto.email}</dd> <dd>{formatCelular(datos.contacto.telefono)}</dd>
        <dd>
          {datos.contacto.calle && ` ${datos.contacto.calle}`}{" "}
          {datos.contacto.numero && ` ${datos.contacto.numero}`}
        </dd>
        {datos.contacto.depto && <dd>{"Depto/Bloque " + datos.contacto.depto}</dd>}
        {datos.contacto.comuna && <dd>{datos.contacto.comuna}</dd>}
      </dl>
    );
  };

  const renderBank = () => {
    return editBank ? (
      <div className="datos-editar">
        <label className="mb-4 datos-editar-titulo">Banco</label>
        <SelectBanco
          className={`field ${datos.banco.bancoError}`}
          variant={fieldVariant}
          disabled={datos.banco.sinCuenta === "true" || datos.banco.sinCuenta === true}
          value={datos.banco.banco}
          onChange={value => dispatch({ type: "SET_BANCO", payload: value })}
        />

        <SelectTipoCuenta
          className={`field ${datos.banco.tipoCuentaError}`}
          variant={fieldVariant}
          disabled={datos.banco.sinCuenta === "true" || datos.banco.sinCuenta === true}
          value={datos.banco.tipoCuenta}
          onChange={value => dispatch({ type: "SET_TIPO_CUENTA", payload: value })}
        />

        <TextField
          className={`field ${datos.banco.numeroCuentaError}`}
          label="Número de cuenta"
          variant={fieldVariant}
          disabled={datos.banco.sinCuenta === "true" || datos.banco.sinCuenta === true}
          value={datos.banco.numeroCuenta}
          inputProps={{
            maxLength: 255
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={({ target }) => dispatch({ type: "SET_NUMERO_CUENTA", payload: target.value })}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={datos.banco.sinCuenta === "true" || datos.banco.sinCuenta === true}
              onChange={() => dispatch({ type: "SET_SIN_CUENTA" })}
            />
          }
          label="No tengo cuenta bancaria"
        />
        <div className="datos-buttons">
          {loadingBank ? (
            <Button className="button" variant="contained" color="primary">
              <CircularProgress size={20} />
            </Button>
          ) : (
            <Button
              className="button"
              variant="contained"
              color="primary"
              disabled={!validateBank()}
              onClick={saveBank}
            >
              Actualizar
            </Button>
          )}
          <Button
            className="button"
            variant="contained"
            color="default"
            onClick={handleCancelEditBank}
          >
            Cancelar
          </Button>
        </div>
      </div>
    ) : (
      <dl>
        <dt className="mb-4">
          Banco{" "}
          <IconButton aria-label="edit" onClick={handleEditBank}>
            <EditIcon />
          </IconButton>
        </dt>
        {!datos.banco.sinCuenta && datos.banco.banco && (
          <>
            <dd>{datos.banco.banco.label}</dd>
          </>
        )}
        {!datos.banco.sinCuenta && datos.banco.tipoCuenta && (
          <>
            <dd>{datos.banco.tipoCuenta.label}</dd>
          </>
        )}
        {!datos.banco.sinCuenta && datos.banco.numeroCuenta && (
          <>
            <dd>{datos.banco.numeroCuenta}</dd>
          </>
        )}
        {datos.banco.sinCuenta && (
          <>
            <dt>Sin cuenta bancaria</dt>
          </>
        )}
      </dl>
    );
  };

  if (!afiliado) {
    return <Redirect to="/" />;
  }

  return (
    <div className="ver-perfil">
      <div className="top">
        <h2>Perfil</h2>
      </div>

      <div className="datos">
        {renderPersonal()}
        {renderContact()}
        {renderBank()}
      </div>

      <Collapse in={error}>
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {errorMsg}
        </Alert>
      </Collapse>
    </div>
  );
};

export default Ver;
