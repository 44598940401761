const logger = (title, body, severity) => {
  switch (severity) {
    case "error":
      console.error(title, body);
      break;
    case "warn":
      console.warn(title, body);
      break;
    default:
      console.log(title, body);
      break;
  }
};

export default logger;
