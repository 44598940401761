import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { formatNumber } from "../functions";
import { PAYMENT_OPTIONS } from "../constants";

const ComprobanteReembolso = ({ id, loading, monto, opcionPago }) => {
  return (
    <div className="comprobante">
      <h3>
        {loading
          ? "Estamos ingresando su solicitud de reembolso..."
          : "Solicitud de reembolso ingresada con éxito"}
      </h3>
      {loading ? (
        <Skeleton animation="wave" height={90} />
      ) : (
        <p>
          Monto solicitud de reembolso
          <br />
          <span className="monto">{formatNumber(monto, "currency")}</span>
        </p>
      )}

      {loading ? (
        <Skeleton animation="wave" height={200} />
      ) : (
        <>
          {opcionPago.metodo === PAYMENT_OPTIONS.transferencia && (
            <p>
              Una vez validada tu solicitud, realizaremos una transferencia a la{" "}
              {opcionPago.tipoCuenta.label} N° {opcionPago.numeroCuenta} del{" "}
              {opcionPago.banco.label}.
            </p>
          )}
          {opcionPago.metodo === PAYMENT_OPTIONS.valeVista && (
            <p>Una vez validada tu solicitud, generaremos un vale vista para el pago.</p>
          )}
        </>
      )}

      {loading ? (
        <Skeleton animation="wave" height={120} />
      ) : (
        <p>Recibirás una copia del comprobante en tu correo electrónico.</p>
      )}

      {loading ? (
        <Skeleton animation="wave" height={120} />
      ) : (
        <p>
          Número de transacción:
          <br />
          <span className="monto">{id}</span>
        </p>
      )}
    </div>
  );
};

export default ComprobanteReembolso;
