import React, { useContext } from "react";
import { AppContext } from "../pages/App";

const TopBar = () => {
  const { authData, afiliado, dispatch } = useContext(AppContext);

  return (
    <header className="top-bar">
      <a href="/" className="logo">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/logo_serviclick.png`}
          alt="Serviclick"
        />
      </a>

      {afiliado ? (
        <>
          <div className="hamburger" onClick={() => dispatch({ type: "SHOW_SIDE_BAR" })}></div>
          <div className="username">
            {afiliado.body_contacto.primerNombre} {afiliado.body_contacto.apellidoPaterno}
          </div>
        </>
      ) : (
        <div className="username">{authData.attributes.email}</div>
      )}

      <div className="clearfix"></div>
    </header>
  );
};

export default TopBar;
