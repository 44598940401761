import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { formatNumber } from "../functions";
import { AppContext } from "../pages/App";

const MisReembolsos = ({ reembolsos }) => {
  const { onUpdateReembolso } = useContext(AppContext);
  const matchesMobile = useMediaQuery("(max-width:768px)");
  const [abiertos, setAbiertos] = useState([]);
  const [anteriores, setAnteriores] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if (reembolsos) {
      const reembolsosAbiertos = reembolsos.filter(reembolso => reembolso.estado_ticket <= 8);
      const reembolsosAnteriores = reembolsos.filter(reembolso => reembolso.estado_ticket > 8);

      setAbiertos(reembolsosAbiertos);
      setAnteriores(reembolsosAnteriores);
    }
  }, [reembolsos]);

  const handleClick = reembolso => () => {
    onUpdateReembolso(reembolso);

    if (matchesMobile) {
      history.push("/detalle-reembolso");
    }
  };

  const renderLista = (id, type, reembolsos) => {
    return (
      <List
        className="category-list"
        component="nav"
        aria-labelledby={`${id}-list-subheader`}
        subheader={
          <ListSubheader component="div" id={`${id}-list-subheader`} disableGutters>
            {type}
          </ListSubheader>
        }
      >
        <Divider variant="fullWidth" />

        {reembolsos.map((reembolso, index) => (
          <ListItem key={reembolso.id_ticket} button onClick={handleClick(reembolso)}>
            <ListItemAvatar>
              <Avatar className="reembolso-avatar">{reembolso.folio_ticket}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={reembolso.body_ticket.descripcion}
              secondary={formatNumber(reembolso.body_ticket.monto, "currency")}
            />
            <ListItemSecondaryAction>
              <small className="text-muted">
                {new Date(reembolso.fecha_creacion_ticket).toLocaleDateString()}
              </small>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <div className="page mis-reembolsos">
      <h3>Mis Reembolsos</h3>
      <div className="reembolsos-list">
        {renderLista("abiertos", "Abiertos", abiertos)}
        {anteriores.length > 0 && renderLista("anteriores", "Anteriores", anteriores)}
      </div>
    </div>
  );
};

export default MisReembolsos;
