import React, { useContext } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import InboxIcon from "@material-ui/icons/Inbox";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SettingsIcon from "@material-ui/icons/Settings";
import { AppContext } from "../pages/App";

const SideBar = () => {
  const { authState, authData, afiliado, appState, dispatch } = useContext(AppContext);

  const isAfiliado = afiliado !== null;

  const signOut = () => {
    Auth.signOut();
    hideSideBar();
  };

  const hideSideBar = () => {
    dispatch({ type: "HIDE_SIDE_BAR" });
  };

  if (authState !== "signedIn" || !authData) {
    return null;
  }

  return (
    <div className={`side-bar ${appState.showSideBar ? "active" : ""}`}>
      <div className="top-side-bar">
        <button className="close-btn" onClick={hideSideBar}></button>
        <div className="clearfix"></div>
      </div>
      <nav>
        {isAfiliado && (
          <>
            <Link className="link" to="/nuevo-reembolso" onClick={hideSideBar}>
              <NoteAddIcon />
              <span className="text">Nuevo Reembolso</span>
            </Link>
          </>
        )}

        <Link className="link" to="/" onClick={hideSideBar}>
          {isAfiliado ? <InboxIcon /> : <HomeIcon />}
          <span className="text">{isAfiliado ? "Mis Reembolsos" : "Inicio"}</span>
        </Link>

        <Link className="link" to="/perfil" onClick={hideSideBar}>
          <SettingsIcon />
          <span className="text">Datos Personales</span>
        </Link>

        <button className="link" onClick={() => signOut()}>
          <PowerSettingsNewIcon />
          <span className="text">Salir</span>
        </button>
      </nav>
    </div>
  );
};

export default SideBar;
